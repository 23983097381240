.Spinner-svg {
  animation: spin 1s linear infinite;
  height: 3rem;
  width: 3rem;
}

.Spinner-circle {
  opacity: 0.25;
}

.Spinner-path {
  opacity: 0.75;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
